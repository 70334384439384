<template>
    <rq-page-section title="Additional Licenses" collapsible borderless>
        <template #header-actions>
            <ul class="nav">
                <li class="nav-item" v-rq-tooltip.hover.top="addLicenseTooltip">
                <b-button
                    class="btn btn-link btn-theme"
                    automation_id="btn_launch_external_app"
                    v-rq-tooltip.hover.top="{ title: `Add License` }"
                    @click="onAddItem"
                    :disabled="!hasContactAccess || readOnly || !showAdd"
                >
                    Add License
                </b-button>
                </li>
            </ul>
        </template>
        <rqdx-action-data-grid
            ref="dataGrid"
            :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            v-model:search-value="searchText"
            v-model:validationErrors="validationErrors"
            rq-editable
            hide-search
            hide-show-column-chooser
            hide-clear-filters
            @delete="onDeleteItem"
            >
        </rqdx-action-data-grid>
    </rq-page-section>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import {
  ContactLicense,
  Contact,
} from "@/modules/file/order-entry/contacts/models";
import useGridInvokerMethods from "@/shared/composables/useGridInvokerMethods";
import { DateTime } from "luxon";
export default {
  name: "ContactLicenseGrid",
  setup() {
    const { dataGrid, invokeGridMethod, invokeGridComponentMethod } =
      useGridInvokerMethods();

    return {
      dataGrid,
      invokeGridMethod,
      invokeGridComponentMethod,
    };
  },
  props: {
    contactLicenses: { type: Array, default: () => new []() },
    info: { type: Object, default: () => ({}) },
    hasContactAccess: { type: Boolean, default: true },
    readOnly: { type: Boolean, default: false },
  },
  data() {
    return {
      localInfo: {},
      items: [],
      originals: [],
      selectedItem: {},
      validationErrors: [],
      addEventName: "",
      validationContext: {},
      searchText: "",
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.authentication.session.user,
    }),
    ...mapGetters(["lookupHelpers", "lookupItems", "usStatesLookup"]),
    addLicenseTooltip() {
        return this.showAdd ? null : { title: `Use the License and State Issued fields above first.` };
    },
    itemKey() {
      return "contactLicenseID";
    },
    itemTypeNamePlural() {
      return "Contact Licenses";
    },
    itemTypeName() {
      return "Contact License";
    },
    showAdd() {
        return !_.isNullOrEmpty(this.info.stateIssuedContactLicense);
    },
    selectionActions() {
      const self = this;
      return [
        {
          name: "delete",
          text: "Delete",
          eventName: "delete",
          allowMultiSelection: true,
          requireSelection: true,
          tooltip: `Delete ${this.itemTypeName}`,
          disabled: function (e) {
            return !self.hasContactAccess || self.readOnly;
          },
        },
      ];
    },
  },

  watch: {
    info: {
      handler: function (newValue, oldValue) {
        if (
          !_.isNil(newValue) &&
          (_.isEqual(
            _.toPlainObject(newValue),
            _.toPlainObject(this.localInfo)
          ) ||
            (!_.isNil(this.localInfo) &&
              newValue.contactID == this.localInfo.contactID))
        )
          return;

        this.localInfo = new Contact(newValue);
      },
      immediate: true,
      deep: true,
    },
    contactLicenses: {
      handler: function(newValue, oldValue) {
        if(!_.isNil(newValue)) {
          this.originals = _.cloneDeep(this.contactLicenses);
          this.items = _.cloneDeep(this.contactLicenses);
          this.refresh();
        }
      },
      immediate: true,
      deep: true
    },
    validationErrors(newValue, oldValue) {
      const self = this;
      self.$events.emit("update-config-error", {
        message: "Please correct the highlighted errors on screen to continue.",
        hasError: self.validationErrors.length > 0,
      });
    },
  },

  created() {
    const self = this;
    self.initGridConfig();
    self.initListeners();

    this.originals = _.cloneDeep(this.contactLicenses);
    this.items = _.cloneDeep(this.contactLicenses);
  },

  beforeUnmount() {
    this.$events.off(this.addEventName, this.onAddItem);
  },

  methods: {
    elementName(prefix = "", suffix = "") {
      return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`);
    },
    initGridConfig() {
      const self = this;
      let sameStateValidationRule = {
          type: "custom",
          message: "Only a single license per state is allowed",
          validationCallback: item => {
            if (_.isNil(item.value)) return true;
            let rows = this.$refs.dataGrid.gridInstance.getVisibleRows();
            let states = [self.info.stateIssuedLicense, ...rows.map(row => row.data.state)].filter(state => !_.isNil(state));
            return new Set(states).size === states.length;
          }
      };
      let licenseValidationRule = {
        type: "custom",
        message: "License Number is required",
        validationCallback: item => {
          return _.isNil(item.data.state) ? true : !_.isNullOrEmpty(item.value)
        }
      }
      let gridColumns = [
      {
          dataField: "licenseNumber",
          dataType: "string",
          caption: "License Number",
          validationRules: [licenseValidationRule],
          editorOptions: { maxLength: 50 },
        },
        {
          dataField: "state",
          dataType: "number",
          caption: "State Issued",
          validationRules: [sameStateValidationRule],
          lookup: {
            dataSource: self.usStatesLookup,
            displayExpr: "id",
            valueExpr: "id",
          },
          setCellValue(rowData, value) {
            rowData.state = value;
          },
          editorOptions: { showClearButton: true },
        },
        {
          dataField: "expirationDate",
          dataType: "date",
          caption: "Expiration Date",
          cellTemplate(cellElement, cellInfo) {
            let value = DateTime.fromJSDate(cellInfo.value).startOf('day');
            let now = DateTime.now().startOf('day');
            let isInPast =  value < now;

            cellElement.addClass(isInPast ? "rq-overridden" : "");
            cellElement.append(value.isValid ? value.toFormat("M/d/yyyy") : "")
          }
        },
      ];

      self.gridConfig = {
        sorting: { mode: "single" },
        columns: gridColumns,
        paging: { enabled: false },
        onInitNewRow: (e) => {
          e.data.contactLicenseID = 0;
          e.data.contactID = self.localInfo.contactID;
        },
        onEditorPreparing: (e) => {
          if (e.parentType !== "dataRow") return;
          if (self.readOnly || !self.hasContactAccess)
            e.editorOptions.disabled = true;
        },
      };
      self.gridDataSource = {
        key: self.itemKey,
        load() {
          return Promise.resolve(
            _.filter(self.items, (item) => !item.isDeleted)
          );
        },
        insert: self.onGridInsert,
        update: self.onGridUpdate,
      };
    },
    initListeners() {
      this.addEventName = `add:${this.elementName()}`;
      this.$events.on(this.addEventName, this.onAddItem);
    },
    emitValue() {
      this.$emit("update:contact-licenses", this.items);
    },
    onAddItem() {
      if(_.isNil(this.$refs.dataGrid.gridInstance)) return;
      this.$refs.dataGrid.gridInstance.addRow();
    },
    onDeleteItem(e) {
      if (!e || !e.data) return;
      const self = this;
      let items = e.data;
      let itemLabel =
        items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName;
      let okHandler = function (args) {
        self.deleteItems(items);
        return true;
      };
      self.$dialog.confirm(
        "Confirm Delete",
        `Are you sure you want to delete the selected ${itemLabel}?`,
        okHandler,
        null,
        { cancelTitle: "No", okTitle: "Yes" }
      );
    },
    onGridInsert(values) {
      const self = this;
      let originalItem = new ContactLicense();
      let newItem = new ContactLicense(values);
      newItem.contactLicenseID = self.guid();
      newItem.isNew = true;

      let changes = self.getAuditChanges(
        originalItem.toDataObject(),
        newItem.toDataObject()
      );

      self.items.push(newItem);

      return self.save(newItem, changes)
        .then((result) => {})
        .catch((error) => {
          self.$toast.error({ message: `Error saving the contact license.` });
        });
    },
    onGridUpdate(key, values) {
      const self = this;
      let itemIndex = _.findIndex(
        self.items,
        (item) => item.contactLicenseID === key
      );
      if (itemIndex < 0) return self.onGridInsert(values);
      let originalItem = _.cloneDeep(self.items[itemIndex]);
      let contactLicense = self.items[itemIndex];
      let updatedItem = new ContactLicense(
        _.assign({}, contactLicense, values)
      );
      let changes = self.getAuditChanges(
        originalItem.toDataObject(),
        updatedItem.toDataObject()
      );
      contactLicense.isUpdated = contactLicense.isNew ? false : true;

      return self.save(updatedItem, changes)
        .then((result) => {})
        .catch((error) => {
          self.$toast.error({ message: `Error saving the contact license.` });
        });
    },
    save(updatedItem, changes) {
      const self = this;
      if (changes.length == 0) {
        return Promise.resolve(updatedItem);
      }
      let targetRowIndex = _.findIndex(
        self.items,
        (item) => item.contactLicenseID == updatedItem.contactLicenseID
      );

      self.mapChanges(self.items[targetRowIndex], changes);
      self.emitValue();

      let apiPromise = new Promise((resolve) => {
        resolve(self.items[targetRowIndex]);
      });
      return self.$rqBusy
        .wait(apiPromise)
        .then((result) => {
          self.refresh();
          return result;
        })
        .catch((error) => {
          return error;
        });
    },
    mapChanges(item, changes) {
      if (!item) return;
      changes.forEach((change) => {
        item[change.name] = change.new;
      });
    },
    deleteItems(items) {
      const self = this;
      if (!_.isArray(items) || _.isEmpty(items)) return;
      _.forEach(items, (delItem) => {
        let itemIndex = _.findIndex(
          self.items,
          (item) => item.contactLicenseID === delItem.contactLicenseID
        );
        if (itemIndex >= 0) {
          if (delItem.isNew) self.items.splice(itemIndex, 1);
          else self.items[itemIndex].isDeleted = true;
        }
      });
      self.refresh();
      self.emitValue();
    },
    refresh() {
      if(_.isNil(this.$refs.dataGrid?.gridInstance)) return;
      this.$refs.dataGrid.gridInstance.clearSelection();
      this.$refs.dataGrid.gridInstance.refresh();
    },
    //TG - To create random guids use _.createUuid() which internally uses uuid.v4() from the "uuid" library
    guid() {
      return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    },
  },
};
</script>